/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Assignment } from '../types/assignment';

const stateName = 'assignmentFilter';

interface SelectItem {
  id: number | string;
  label: string;
}

interface AssignmentFilter {
  projects: SelectItem[];
  loadTypes: SelectItem[];
  assignmentStatus: SelectItem[];
}

const initialState: AssignmentFilter = {
  projects: [],
  loadTypes: [],
  assignmentStatus: [],
};

const assignmentFilterSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setProjects(state, action: PayloadAction<SelectItem[]>) {
      state.projects = action.payload;
    },
    setLoadTypes(state, action: PayloadAction<SelectItem[]>) {
      state.loadTypes = action.payload;
    },
    setAssignmentStatus(state, action: PayloadAction<SelectItem[]>) {
      state.assignmentStatus = action.payload;
    },
    reset(state) {
      Object.assign(state, initialState);
    },
  },
});

export const useIsFiltersActive = (): boolean => {
  const {
    projects, loadTypes, assignmentStatus,
  } = useSelector(
    (state: { [stateName]: AssignmentFilter }) => state[stateName],
  );
  return !!(projects.length || loadTypes.length || assignmentStatus.length);
};

export const useFilteredByStatus = (assignments: Assignment[]) => {
  const { assignmentStatus } = useSelector(
    (state: { [stateName]: AssignmentFilter }) => state[stateName],
  );

  const filteredAssignments = useMemo(() => {
    if (assignmentStatus.length) {
      return assignments.filter((a) => assignmentStatus.some((t) => a.status === t.id));
    }
    return assignments;
  }, [assignments, assignmentStatus]);

  return filteredAssignments.map(({ id }) => id);
};

export const useFilteredAssignment = (assignments: Assignment[]) => {
  const {
    projects, loadTypes, assignmentStatus,
  } = useSelector(
    (state: { [stateName]: AssignmentFilter }) => state[stateName],
  );
  const filteredAssignments = useMemo(() => {
    let fa = [...assignments.filter((a) => a.status !== 'Deleted')];
    if (projects.length) {
      // eslint-disable-next-line max-len
      fa = fa.filter((a) => projects.some((p) => a.toProject?.id === p.id || a.fromProject?.id === p.id));
    }
    if (loadTypes.length) {
      fa = fa.filter((a) => loadTypes.some((l) => a.loads.some((al) => al.type?.id === l.id)));
    }
    if (assignmentStatus.length) {
      fa = fa.filter((a) => assignmentStatus.some((t) => a.status === t.id));
    }
    return fa.map(({ id }) => id);
  }, [assignments, projects, loadTypes, assignmentStatus]);

  return filteredAssignments;
};

export const {
  setProjects, setLoadTypes, setAssignmentStatus, reset,
} = assignmentFilterSlice.actions;
export default persistReducer({ key: 'assignmentFilter', storage }, assignmentFilterSlice.reducer);
