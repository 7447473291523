import { getAssignmentProject } from './functions';
import { formatTime, autoFormat } from './dates';
import { Assignment } from '../types/assignment';
import { translateMassType } from './translate';
import {
  canEditFromTime,
  canEditToTime,
} from './assignmentPermissions';
import { Project } from '../types/project';
import { AssignmentStatus } from '../types/enums/assignmentStatus';
import { AssignmentType } from '../types/enums/assignmentType';
import {
  APPROVED_COLOR,
  CANCELLED_COLOR,
  COMPLETED_COLOR,
  DELETED_COLOR,
  NOT_DELIVERED_COLOR,
  STARTED_COLOR,
  UNAVAILABILITY_COLOR,
  UNDER_PLANNING_COLOR,
  VEHICLE_SERVICE_COLOR,
} from '../../shared/constants';
import { ServiceType } from '../types/unavailability';
import { OrderType } from '../types/enums/orderType';

export interface TimelineEvent {
    id: string;
    start: Date | string;
    end: Date | string;
    title: string;
    extendedProps: {
      type: 'unavailability' | 'assignment' | ServiceType;
      lock: boolean;
      from: string;
      to: string;
      orderType: string;
      assignmentType?: AssignmentType;
      title: string;
      loadType: string;
      time: string,
      contact: string,
      isInternal: boolean;
      startTime: string;
      endTime: string;
    },
    resourceId: string;
    editable: boolean,
    startEditable: boolean,
    durationEditable: boolean,
    resourceEditable: boolean,
    color: string,
    backgroundColor?: string,
    display?: 'block' | 'background' | 'none',
    textColor?: string,
}

export const projectLabel = (project?: Project) => (project ? `${project.id} - ${project.projectName}` : '');

export const getEventColorFromStatus = (status: AssignmentStatus) => {
  switch (status) {
    case 'UnderPlanning':
      return UNDER_PLANNING_COLOR;
    case 'Approved':
      return APPROVED_COLOR;
    case 'Started':
      return STARTED_COLOR;
    case 'Completed':
      return COMPLETED_COLOR;
    case 'Cancelled':
      return CANCELLED_COLOR;
    case 'NotDelivered':
    case 'VerifiedNotDelivered':
      return NOT_DELIVERED_COLOR;
    case 'Deleted':
      return DELETED_COLOR;
    default:
      return UNDER_PLANNING_COLOR;
  }
};

export const getEventColorForDriver = (status: AssignmentStatus) => {
  switch (status) {
    case 'Approved': // Received assignment
      return APPROVED_COLOR; // Blue
    case 'Started': // Started assignment
      return UNDER_PLANNING_COLOR; // Orange
    case 'Completed': // Finished assignment
      return STARTED_COLOR; // Green
    case 'NotDelivered': // Could not deliver assignment
    case 'VerifiedNotDelivered':
      return NOT_DELIVERED_COLOR; // Red
    case 'VehicleService':
      return VEHICLE_SERVICE_COLOR; // Purple
    default: // Any other status
      return COMPLETED_COLOR; // Grey
  }
};

export const getEventColor = (a: Assignment) => {
  if (a.status === 'Unavailable') return UNAVAILABILITY_COLOR;
  if (a.status !== 'VehicleService') return getEventColorFromStatus(a.status);
  return VEHICLE_SERVICE_COLOR;
};

/**
 * Gets the required data for the timeline from an assignment
 *
 * @param assignment Assignment to convert
 * @returns Data to use in timeline as a resource
 */
export const getTimelineEvent = (
  assignment: Assignment,
  shouldHide: boolean = false,
  ghost: boolean = false,
  readonly = false,
): TimelineEvent => {
  const project = getAssignmentProject(assignment);
  const a = assignment;

  const ghostStyle = ghost
    ? {
      backgroundColor: 'rgba(0,0,0,0)',
      textColor: 'black',
    }
    : {};

  const type = (() => {
    if (a.status === 'Unavailable') return 'unavailability';
    if (a.status !== 'VehicleService') return 'assignment';
    return (a.orderComment as ServiceType) ?? 'unavailability';
  })();

  return {
    id: `${a.id}`,
    start: new Date(a.startTime),
    end: new Date(a.endTime),
    title: a.title || `${project?.id} - ${project?.projectName || project?.projectName}`,
    display: shouldHide ? 'none' : 'block',
    extendedProps: {
      type,
      lock: a.lockState,
      from: projectLabel(a.fromProject),
      to: projectLabel(a.toProject),
      orderType: a.order ? translateMassType(a.order) : 'Utilgjengelig',
      assignmentType: a.type,
      title: a.title,
      loadType: a.loads.map((l) => l.type?.name || l?.wasteType || '(tom)').filter((l) => l).join(', '),
      time: `${formatTime(a.startTime)} - ${formatTime(a.endTime)}`,
      contact: `${a.order?.contactPersonName} (${a.order?.contactPersonPhoneNumber})`,
      isInternal: a.type === 'MassInternal',
      startTime: autoFormat(new Date(a.startTime), "dd.MM.yyyy 'kl.' HH:mm"),
      endTime: autoFormat(new Date(a.endTime), "dd.MM.yyyy 'kl.' HH:mm"),
    },
    resourceId: a.vehicle?.internalNumber,
    // editable
    editable: !readonly && (canEditFromTime(a.status) || canEditToTime(a.status)),
    // allowed to be moved on timeline
    startEditable: !readonly && type === 'assignment',
    // allowed to adjust time by dragging event duration
    durationEditable: !readonly && canEditToTime(a.status),
    // allowed to change vehicle/driver
    resourceEditable: !readonly && type === 'assignment',
    color: getEventColor(a),
    textColor: a.status === 'Unavailable' ? '#222' : 'white',
    ...ghostStyle,
  };
};

export const getTypeColor = (type?: AssignmentType | OrderType | null) => {
  switch (type) {
    case 'HaulHook':
    case 'HaulSemi':
      return 'success';
    default:
      return 'info';
  }
};
